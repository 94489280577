import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateAdvertisement = () => {
  const [picture, setPicture] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  // Redirect to login page if user isn't logged in
  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, [token, navigate]);

  const createAdvertisement = async (e) => {
    e.preventDefault();
    // console.log("Submitting Advertisement Data:", { picture });

    const advertisementData = new FormData();
    advertisementData.append("picture", picture);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/advertisement`,
        advertisementData,
        {
          withCredentials: true,
          headers: {
            Authorization:`Bearer ${token}`,
          },
        }
      );
 
      if (response.status === 201) {
        return navigate("/"); // Redirect after successful creation
      }
    } catch (err) {
      console.error("Error creating advertisement:", err); // Log the entire error for debugging
      setError(err.response?.data?.message || "Error creating advertisement");
    }
  };

  return (
    <section className="create-post">
      <div className="createcontainer">
        <h2>Create Advertisement</h2>
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-advertisement__form" onSubmit={createAdvertisement}>
          <input
            type="file"
            onChange={(e) => setPicture(e.target.files[0])}
            accept="png,jpg,jpeg"
            required
          />
          <button type="submit" className="btn primary">
            Create Advertisement
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateAdvertisement;