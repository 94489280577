import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NepaliDate from 'nepali-date';

const PostAuthor = ({ authorID, createdAt }) => {
  const [author, setAuthor] = useState(null);
  
  // console.log(authorID);

  useEffect(() => {
    const getAuthor = async () => {
    
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/${authorID}`);
        setAuthor(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (authorID) {
      getAuthor();
    }
  }, [authorID]);

  // Validate and format the date
  const isValidDate = (date) => !isNaN(new Date(date).getTime());
  const formattedDate = isValidDate(createdAt) ? new Date(createdAt) : new Date();

  // Convert to Nepali date
  const nepaliDate = new NepaliDate(formattedDate).format('YYYY-MM-DD');

  return (
    <Link to={`/posts/users/${authorID}`} className='post__author'>
      {/* <div className="post__author-avatar">
        <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${author?.avatar}`} alt='' />
      </div> */}
      <div className="post__author-details">
        <small>{nepaliDate}</small>
      </div>
    </Link>
  );
};

export default PostAuthor;
