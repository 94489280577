import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/images/458775590_122100278468513686_6113659977926943335_n.jpg";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { UserContext } from "../context/userContext";
import { AdminContext } from "../context/adminContext";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";

const Header = () => {
  const [isNavShowing, setIsNavShowing] = useState(window.innerWidth > 800);
  const [nepalTime, setNepalTime] = useState(new Date());
  const [virginiaTime, setVirginiaTime] = useState(new Date());
  const { currentUser } = useContext(UserContext);
  const { isAdmin } = useContext(AdminContext);
  const location = useLocation();
  const [isAdminPage, setIsAdminPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setIsAdminPage(location.pathname.startsWith("/admin"));
  }, [location]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNepalTime(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Kathmandu" })
      );
      setVirginiaTime(
        new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("ne-NP", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString("ne-NP", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // Toggle menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when scrolling
  useEffect(() => {
    // const handleScroll = () => {
    //   if (isOpen) {
    //     setIsOpen(false);
    //   }
    // };
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [isOpen]);

  // Close menu when a nav link is clicked
  const closeMenuOnClick = () => {
    setIsOpen(false);
    window.scrollTo(0, 0);
  };

  const closeNavHandler = () => {
    if (window.innerWidth < 800) {
      setIsNavShowing(false);
    } else {
      setIsNavShowing(true);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement your search functionality here
  };

  return (
    <>
      {/* Date and Time Header */}
      <header className="header">
  {/* Hide header on small screens */}
  <div className="header-content">
    {/* Date-Time Section - Hidden after login */}
    {!currentUser?.id && (
      <div className="time-container">
        <div className="date-time">
          <ReactCountryFlag
            countryCode="NP"
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title="Nepal"
          />
          <strong>Nepal:</strong> {formatDate(nepalTime)} {formatTime(nepalTime)}
        </div>
        <div className="date-time">
          <ReactCountryFlag
            countryCode="US"
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title="US"
          />
          <strong>Virginia:</strong> {formatDate(virginiaTime)} {formatTime(virginiaTime)}
        </div>
      </div>
    )}

    {/* Social Media Section - Hidden after login */}
    {!currentUser?.id && (
      <div className="social-media">
        <Link to="https://www.facebook.com/nepalamericanews" className="social-icon facebook">
          <span className="icon-wrapper">
            <FaFacebook />
          </span>
        </Link>
        <Link to="#" className="social-icon twitter">
          <span className="icon-wrapper">
            <FaTwitter />
          </span>
        </Link>
        <Link to="#" className="social-icon youtube">
          <span className="icon-wrapper">
            <FaYoutube />
          </span>
        </Link>
      </div>
    )}

    {/* Login Section */}
    <div className="login-link">
      {currentUser?.id ? (
        <ul className="login-items">
          <li>
            <Link to={`/profile/${currentUser.id}`} onClick={closeNavHandler}>
              {currentUser.name}
            </Link>
          </li>
          <li>
            <Link to="/create" onClick={closeNavHandler}>
              Create Post
            </Link>
          </li>
          <li>
            <Link to="/event" onClick={closeNavHandler}>
              Create Event
            </Link>
          </li>
          <li>
            <Link to="/advertisement" onClick={closeNavHandler}>
              Create Advertisement
            </Link>
          </li>
          <li>
            <Link to="/logout" onClick={closeNavHandler}>
              Logout
            </Link>
          </li>
        </ul>
      ) : (
        !isAdminPage && (
          <li>
            <Link to="/login" onClick={closeNavHandler}>
              Login
            </Link>

      
          </li>
          
        )
        
      )}
    </div>
  </div>
</header>



      {/* Logo in Center */}
      <div className="nav-logo">
        <Link to="/" onClick={closeMenuOnClick}>
          <img src={Logo} alt="Navbar Logo" className="logo" />
        </Link>
      </div>

      {/* Main Navbar */}
      <nav className="navbar">
        <div className="nav-container">
          {/* Mobile Toggle Button */}
          <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? <AiOutlineClose /> : <FaBars />}
          </button>

          {/* Nav Links */}
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
         
            <li>
              <Link to="/" onClick={closeMenuOnClick}>
                मुख्य पृष्ठ
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/समाचार" onClick={closeMenuOnClick}>
                समाचार
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/प्रवास" onClick={closeMenuOnClick}>
                प्रवास
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/साहित्य" onClick={closeMenuOnClick}>
                साहित्य
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/मनोरञ्जन" onClick={closeMenuOnClick}>
                मनोरञ्जन
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/खेलकुद" onClick={closeMenuOnClick}>
                खेलकुद
              </Link>
            </li>
            <li>
              <Link to="/posts/categories/बिचार" onClick={closeMenuOnClick}>
                बिचार
              </Link>
            </li>

            <li>
              <Link
                to="/posts/categories/अन्तर्वार्ता"
                onClick={closeMenuOnClick}
              >
                अन्तर्वार्ता
              </Link>
             
            </li>
            <li>
              <Link
                to="/posts/categories/स्वास्थ्य जिज्ञासु"
                onClick={closeMenuOnClick}
              >
                स्वास्थ्य जिज्ञासु
              </Link>
            </li>
            <li>
              <Link
                to="/posts/categories/लोकप्रिय"
                onClick={closeMenuOnClick}
              >
            लोकप्रिय
              </Link>
            </li>
          
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
