import React, { useState } from 'react';
import { ADToBS, BSToAD } from 'bikram-sambat-js';

const DateConverter = () => {
  const [englishDate, setEnglishDate] = useState('');
  const [nepaliDate, setNepaliDate] = useState('');
  const [convertedEnglishToNepali, setConvertedEnglishToNepali] = useState('');
  const [convertedNepaliToEnglish, setConvertedNepaliToEnglish] = useState('');

  // Convert English (AD) to Nepali (BS)
  const handleEnglishToNepali = () => {
    if (englishDate) {
      console.log('Input English Date:', englishDate);

      const dateObject = new Date(englishDate);

      try {
        const nepali = ADToBS(dateObject); // Convert AD to BS
        console.log('Full ADToBS Output:', nepali); // Log the full output to console

        // Display full structure in the UI for debugging
        setConvertedEnglishToNepali(`ADToBS: ${JSON.stringify(nepali)}`);
        
        // Uncomment the following lines after inspecting the output:
        // const { bsYear, bsMonth, bsDay } = nepali;
        // setConvertedEnglishToNepali(`${bsYear}-${bsMonth}-${bsDay}`);
      } catch (error) {
        console.error('Conversion error:', error);
        setConvertedEnglishToNepali('Error during conversion.');
      }
    }
  };

  // Convert Nepali (BS) to English (AD)
  const handleNepaliToEnglish = () => {
    if (nepaliDate) {
      console.log('Input Nepali Date:', nepaliDate);

      try {
        const english = BSToAD(nepaliDate); // Convert BS to AD
        console.log('Full BSToAD Output:', english); // Log the full output to console

        // Display full structure in the UI for debugging
        setConvertedNepaliToEnglish(` BSToAD Output: ${JSON.stringify(english)}`);

        // Uncomment the following lines after inspecting the output:
        // const { adYear, adMonth, adDay } = english;
        // setConvertedNepaliToEnglish(`${adYear}-${adMonth}-${adDay}`);
      } catch (error) {
        console.error('Conversion error:', error);
        setConvertedNepaliToEnglish('Error during conversion.');
      }
    }
  };

  return (
    <div className="date-converter">
    <div className='datediv'></div>
      <h2>Date Converter</h2>
      {/* <h3>Converted English to Nepali Date:</h3> */}
      <div className="input-container">
        <label>Enter English Date (YYYY-MM-DD):</label>
        <input
          type="date"
          value={englishDate}
          onChange={(e) => setEnglishDate(e.target.value)}
        />
        <button onClick={handleEnglishToNepali}>Convert to Nepali</button>
      </div>

      <div className="result">
      
        <pre>{convertedEnglishToNepali}</pre>
      </div>
      {/* <h3>Converted Nepali to English Date:</h3> */}
      <div className="input-container">
        <label>Enter Nepali Date (YYYY-MM-DD):</label>
        <input
          type="text"
          placeholder="2078-01-01"
          value={nepaliDate}
          onChange={(e) => setNepaliDate(e.target.value)}
        />
        <button onClick={handleNepaliToEnglish}>Convert to English</button>
      </div>

      <div className="result">
      
        <pre>{convertedNepaliToEnglish}</pre>
      </div>
    </div>
  );
};

export default DateConverter;
