import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/userContext.js';
import { GiNewspaper } from "react-icons/gi";
// import './Login.css'; // Import the styles

const Login = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(UserContext);

  const changeInputHandler = (e) => {
    setUserData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/login`, userData);
      const user = await response.data;
      setCurrentUser(user);
      navigate('/');
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <section className="login">
      <div className="logincontainerr">
        <div className="login__box">
          <div className="login__header">
            <h2>Sign In</h2>
            <i className="newspaper-icon"><GiNewspaper/> </i>

          </div>
          <form className="form login__form" onSubmit={loginUser}>
            {error && <p className="form__error-message">{error}</p>}
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={userData.email}
              onChange={changeInputHandler}
              autoFocus
              className="input-field"
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={userData.password}
              onChange={changeInputHandler}
              className="input-field"
            />
            <button type="submit" className="btn primary">Login</button>
          </form>
          {/* <small className="account-link">
            Don't Have An Account? <Link to="/register">Sign Up</Link>
          </small> */}
        </div>
      </div>
    </section>
  );
};

export default Login;
