import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../context/userContext";
import DeleteEvent from "../pages/DeleteEvents";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/events`,
          {
            params: {
              page: currentPage,
              limit: 3, // Fetch only 3 events per page
              sort: "desc",
            },
          }
        );

        setEvents(response.data.events);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } catch (error) {
        console.error("Error fetching events:", error);
        setError("Error fetching events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePageSelect = (page) => {
    setCurrentPage(page);
  };
  const fallbackImage =
    "https://res.cloudinary.com/your-cloud-name/image/upload/v1234567890/image.jpg";

  return (
    <section className="events-section">
      <h2>Upcoming Events</h2>

      <>
        {events?.length > 0 ? (
          <div className="events__container">
            {events.map((event) => (
              <article key={event._id} className="event-card">
                {currentUser && <DeleteEvent eventId={event._id} />}
                <div className="event__thumbnail">
              
                  <img
                   className="event_image"
                    src={event.picture} // Use the thumbnail or fallback image
                    alt={event.title}
                   
                  />
                   
                </div>
                <div className="event__content">
                  <h3>{event.title}</h3>
                  <p>{event.short_description}</p>
                  <p>{new Date(event.date).toLocaleDateString()}</p>
                  <p>{event.price && `Price: ${event.price}`}</p>

                </div>
              </article>
            ))}
          </div>
        ) : (
          <h2 className="center">No Events Found</h2>
        )}
        <div className="pagination-container">
          <button
            className="pagination-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              className={`pagination-button ${
                currentPage === number + 1 ? "active" : ""
              }`}
              onClick={() => handlePageSelect(number + 1)}
            >
              {number + 1}
            </button>
          ))}
          <button
            className="pagination-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </>
    </section>
  );
};

export default Events;
