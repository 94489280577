import React, { useContext, useState } from "react";
import { UserContext } from "../context/userContext";
import axios from "axios";
import Loader from "../components/Loader";

const DeleteAdvertisement = ({ advertisementId,ad }) => {
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(""); // State for error messages

  const removeAdvertisement = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/advertisement/${advertisementId}`,
        
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/advertisement`
        );
        // console.log("API Response:", response.data); // Log the entire response
  
        if (response.data && response.data.advertisements) {
          ad(response.data.advertisements);
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.log(error)
      console.error("Couldn't Delete Advertisement", error);
      setError("Failed to delete advertisement.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'right' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
          <button
            className="delete"
            onClick={removeAdvertisement}
            style={{
              padding: '8px 16px',
              backgroundColor: '#ff4757',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              transition: 'background-color 0.3s ease',
              margin: '10px auto',
              display: 'inline-block'
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#ff6b81')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#ff4757')}
          >
            Delete
          </button>
        </>
      )}
    </div>
  );
};

export default DeleteAdvertisement;
