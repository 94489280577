//rafce (shortcut)
import React from 'react';
import Posts from '../components/Posts';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const Home = () => {
  // Sample events data
  const events = [
    { id: 1, title: 'Event 1', description: 'Description of Event 1' },
    { id: 2, title: 'Event 2', description: 'Description of Event 2' },
    { id: 3, title: 'Event 3', description: 'Description of Event 3' },
    { id: 4, title: 'Event 4', description: 'Description of Event 4' },
  ];

  return (
    <>
      <div className='home'>
        <Posts />
      </div>

      
    </>
  );
};

export default Home;
