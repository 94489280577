import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateEvent = () => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [picture, setPicture] = useState(null); // Holds the file
  const [error, setError] = useState(""); // Error message state
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  // Redirect to login page if user isn't logged in
  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, [token, navigate]);

  const createEvent = async (e) => {
    e.preventDefault();

    const eventData = new FormData();
    eventData.append("picture", picture); // Append the image file
    eventData.append("date", new Date(date).toISOString()); // Ensure the date is in ISO format
    eventData.append("title", title);
    eventData.append("price", price);
    eventData.append("short_description", shortDescription); // Use the correct field name

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/events`,
        eventData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
        }
      );

      if (response.status === 201) {
        navigate("/"); // Redirect on success
      }
    } catch (err) {
      console.error("Error creating event:", err); // Log full error for debugging
      setError(err.response?.data?.message || "Error creating event");
    }
  };

  return (
    <section className="create-post">
      <div className="createcontainer">
        <h2>Create Event</h2>
        {error && <p className="form__error-message">{error}</p>} {/* Display any error messages */}
        <form className="form create-event__form" onSubmit={createEvent}>
          <input
            type="text"
            placeholder="Event Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            type="date"
            placeholder="Event Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Optional"
        />

          <textarea
            placeholder="Short Description"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            required
          />
          <input
            type="file"
            onChange={(e) => setPicture(e.target.files[0])} // Capture the file input
            accept="image/png, image/jpg, image/jpeg"
            required
          />
          <button type="submit" className="btn primary">
            Create Event
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateEvent;
