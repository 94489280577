import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import PostAuthor from "../components/PostAuthor";
import DeletePost from "./DeletePost";
import { UserContext } from "../context/userContext";
import Recommendations from "../components/Recommendations";
import ReviewForm from "../components/ReviewForm";
import ScrollToTop from "../components/Scroll";
import Advertisement from "../components/Advertisment";

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);

  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const getPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/posts/${id}`
        );
        setPost(response.data);
        setLikesCount(response.data.likes?.length || 0);
        setIsLiked(
          response.data.likes?.some((user) => user._id === currentUser?.id)
        );
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getReviews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/posts/${id}/reviews`
        );
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    getPost();
    getReviews();
  }, [id, currentUser?.id]);

  const handleLike = async () => {
    if (!currentUser?.token) {
      console.error("User is not authenticated");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/posts/${id}/like`,
        {},
        {
          headers: { Authorization: `Bearer ${currentUser?.token}` },
        }
      );
      setIsLiked(true);
      setLikesCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error(
        "Error liking the post:",
        error.response?.data || error.message
      );
    }
  };

  const handleUnlike = async () => {
    if (!currentUser?.token) {
      console.error("User is not authenticated");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/posts/${id}/unlike`,
        {},
        {
          headers: { Authorization: `Bearer ${currentUser?.token}` },
        }
      );
      setIsLiked(false);
      setLikesCount((prevCount) => prevCount - 1);
    } catch (error) {
      console.error(
        "Error unliking the post:",
        error.response?.data || error.message
      );
    }
  };

  if (isLoading) {
    return <p>Loading...</p>; // Optionally replace this with a Loader component
  }

  if (!post) {
    return <p>No post found.</p>;
  }

  return (
    <section className="post-detail">
      {error && <p className="error">{error.message}</p>}
      <div className="container post-detail__container">
        <div className="post-detail__header">
          <PostAuthor authorID={post.creator} createdAt={post.createdAt} />
          {currentUser?.id === post?.creator && (
            <div className="post-detail__buttons">
              <Link to={`/posts/${post?._id}/edit`} className="btn sm primary">
                Edit
              </Link>
              <DeletePost postId={id} />
              <p>
                {reviews.length} {reviews.length === 1 ? "review" : "reviews"}
              </p>
            </div>
          )}
        </div>

        <h1>{post.title}</h1>
        <div className="post-detail__thumbnail">
          <img
            src={post.thumbnail} // Access the correct property
            alt={post.title}
            onError={(e) => {
              e.target.onerror = null; // prevents looping
              e.target.src = "path/to/default/image.jpg"; // fallback image
            }}
          />
        </div>
        <p dangerouslySetInnerHTML={{ __html: post.description }}></p>

        <ReviewForm postId={id} setReviews={setReviews} />
        <Advertisement />
        <Recommendations />

        {/* Like/Unlike Button */}
        {/* {currentUser?.id !== post.creator?._id && (
          <div className="like-section">
            <button
              onClick={isLiked ? handleUnlike : handleLike}
              className="btn"
            >
              {isLiked ? "Unlike" : "Like"} ({likesCount})
            </button>
          </div>
        )} */}
      </div>
      <ScrollToTop />
    </section>
  );
};

export default PostDetail;
