import React, { useEffect, useState } from 'react';
import PostItem from './PostItem';
import { Link} from "react-router-dom";
// import Recommendations from '../components/Recommendations';
import Loader from '../components/Loader';
import axios from 'axios';
import Events from './Events';
import ScrollToTop from './Scroll';
import Advertisement from './Advertisment';
import DateConverter from './Dateconverter';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPosts, setTotalPosts] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts`, {
          params: {
            page: currentPage,
            limit: postsPerPage,
            sort: 'desc',
          },
        });
        setPosts(response.data.posts);
        setTotalPosts(response.data.totalPosts);
      } catch (err) {
        console.error(err); // Log the error
      }
      setIsLoading(false);
    };
    fetchPosts();
  }, [currentPage, postsPerPage]);

  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handlePageSelect = (pageNumber) => setCurrentPage(pageNumber);

  // console.log(posts)

  return (
    <>
    {/* <DateConverter/> */}

      <Advertisement />
      <section className='posts'>
        {isLoading ? (
          <Loader />
        ) : posts.length > 0 ? (
          <div className='container posts__container'>
            {posts.map(
              ({
                _id: id,
                thumbnail, // Expecting thumbnail object from the response
                category = 'Uncategorized',
                title = 'No Title',
                description = 'No Description Available',
                // creator: authorID = 'Unknown Author',
                createdAt = 'Unknown Date',
                rating = 0,
                reviews = [],
              }) => (
                <PostItem
                  key={id}
                  postID={id}
                  thumbnail={thumbnail} // Get the thumbnail URL
                  title={title}
                  description={description}
                  // authorID={authorID}
                  createdAt={createdAt}
                  // rating={rating}
                  // reviews={reviews}
                />
              )
            )}
          </div>
        ) : (
          <h2 className='center'>No Posts Found</h2>
        )}

        {totalPages > 1 && (
          <div className='pagination-container'>
            <button
              className='pagination-button'
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((number) => (
              <button
                key={number + 1}
                className={`pagination-button ${currentPage === number + 1 ? 'active' : ''}`}
                onClick={() => handlePageSelect(number + 1)}
              >
                {number + 1}
              </button>
            ))}
            <button
              className='pagination-button'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}

        <Events />
        
        <ScrollToTop />
      </section>
    </>
  );
};

export default Posts;
