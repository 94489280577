import React, { useContext, useState } from "react";
import { UserContext } from "../context/userContext";
import axios from "axios";
import Loader from "../components/Loader";

const Deleteevent = ({ eventId }) => {
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;
  const [isLoading, setIsLoading] = useState(false);

  const removeevent = async () => {

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/events/${eventId}`,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Refresh the events list or handle post-deletion UI update
        window.location.reload(); // You can also call fetchevents from the parent if you want a smoother experience
      }
    } catch (error) {
      // console.log("Couldn't Delete event", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ alignItems: 'right' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <button
          className="delete"
          onClick={removeevent}
          style={{
            padding: '0px 10px',
            backgroundColor: '#ff4757',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
            margin: '10px auto',
            display: 'block',
            float: "right"
          }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#ff6b81')}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#ff4757')}
        >
          Delete
        </button>
      )}
    </div>
  );
};

export default Deleteevent;
