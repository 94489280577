import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

const ReviewForm = () => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [post, setPost] = useState(null); // State to store post data
  const { id } = useParams();

  useEffect(() => {
    // Fetch the post data (including reviews and ratings)
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts/${id}`);
        setPost(response.data); // Set the post data including ratings and reviews
      } catch (error) {
        console.error("Error fetching post data:", error);
      }
    };
    fetchPost();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    if (rating === 0) {
      setError("Please select a rating.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/posts/${id}/reviews`,  // POST request to add the review
        { rating, comment }
      );
      setRating(0);
      setComment("");
      setPost((prevPost) => ({
        ...prevPost,
        reviews: [...prevPost.reviews, response.data], // Add the new review
      }));
    } catch (error) {
      if (error.response) {
        setError(`Error ${error.response.status}: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        setError("Error: No response received from the server.");
      } else {
        setError("Error: An unexpected error occurred.");
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      {/* Display the post title or other info */}
     

      {/* Display the average rating */}
      <h3>
        Reviews ({post?.rating ? post.rating.toFixed(1) : "No ratings yet"})
      </h3>
{/* Display reviews */}
{post?.reviews?.length > 0 ? (
        <ul>
          {post.reviews.map((review, index) => (
            <li key={index}>
              {review.user}: {review.comment} (Rating: {review.rating}/5)
            </li>
          ))}
        </ul>
      ) : (
        <p>No reviews yet.</p>
      )}
      {/* Form to add a new review */}
      <form className="review-form" onSubmit={handleSubmit}>
        {error && <p className="error">{error}</p>}
        <div>
          <label htmlFor="rating">Rating:</label>
          <select
            id="rating"
            value={rating}
            onChange={(e) => setRating(parseInt(e.target.value))}
            required
          >
            <option value="0">Select Rating</option>
            <option value="1">1 - Poor</option>
            <option value="2">2 - Fair</option>
            <option value="3">3 - Good</option>
            <option value="4">4 - Very Good</option>
            <option value="5">5 - Excellent</option>
          </select>
        </div>
        <div>
          <label htmlFor="comment">Comment:</label>
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit Review"}
        </button>
      </form>

      
    </div>
  );
};

export default ReviewForm;
