import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from '../context/userContext'; // Assuming you have a UserContext to get current user
import Loader from '../components/Loader'; // Assuming you have a Loader component
import DeleteAdvertisement from '../pages/DeleteAdvertisement'; // Import the delete component

const Advertisement = () => {
  const [advertisements, setAdvertisements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(UserContext); // Get the current user context

  // Fetch advertisements from the backend
  const fetchAdvertisements = async () => {
    setLoading(true);
    setError(null);
    

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/advertisement`
      );
      // console.log("API Response:", response.data); // Log the entire response

      if (response.data && response.data.advertisements) {
        setAdvertisements(response.data.advertisements);
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response
          ? error.response.status
          : "Unknown Status";
        setError(
          `Failed to load advertisements: ${error.message} (Status: ${status})`
        );
      } else {
        console.error("Unknown error:", error);
        setError("An unknown error occurred while loading advertisements");
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch advertisements on component mount
  useEffect(() => {
    fetchAdvertisements();
  }, []);

  
  return (
    <section className="advertisement-section">
      {loading ? (
        <Loader /> // Show loader while fetching
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : advertisements?.length > 0 ? (
        <div className="advertisements__container">
          {advertisements.map((ad) => {
            // console.log("Advertisement Data:", ad); // Log advertisement data to debug
            return (
              <div key={ad._id} className="ad_image">
                {currentUser && (
                  <DeleteAdvertisement advertisementId={ad._id}
                   ad= {setAdvertisements}
                />
                )}
                <img
                  src={ad.picture} // Use the Cloudinary URL directly
                  alt="Advertisement"
                  className="advertisement-image"
                />
              </div>
            );
          })}
        </div>
      ) : (
        <h2 className="center">No Advertisements Found</h2>
      )}
    </section>
  );
};

export default Advertisement;
