import React from "react";
import { Link } from "react-router-dom";
import PostAuthor from "./PostAuthor";
import ScrollToTop from "./Scroll";

const PostItem = ({
  postID,
  title,
  description,
  thumbnail, // Expecting this to be a string URL
  createdAt,
}) => {
  const shortDescription =
    description.length > 145 ? description.substr(0, 145) + "..." : description;
  const postTitle = title.length > 30 ? title.substr(0, 30) + "..." : title;
  // console.log(thumbnail)

  // Default image URL for fallback
  const fallbackImage = 'https://res.cloudinary.com/your-cloud-name/image/upload/v1234567890/image.jpg';

  return (
    <>
      <article className="post">
        <Link to={`/posts/${postID}`}>
          <div className="post__thumbnail">
            <img
              src={thumbnail} // Use the thumbnail or fallback image
              alt={title}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop if image fails
                e.target.src = fallbackImage; // Fallback image
              }}
            />
          </div>

          <div className="post__content">
            <h3>{postTitle}</h3>
            <p dangerouslySetInnerHTML={{ __html: shortDescription }}></p>

            <div className="post__footer">
              <PostAuthor createdAt={createdAt} />
            </div>
          </div>
        </Link>
      </article>
      <ScrollToTop />
    </>
  );
};

export default PostItem;
